exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-desarrollos-js": () => import("./../../../src/pages/desarrollos.js" /* webpackChunkName: "component---src-pages-desarrollos-js" */),
  "component---src-pages-hablemos-js": () => import("./../../../src/pages/hablemos.js" /* webpackChunkName: "component---src-pages-hablemos-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-style-js": () => import("./../../../src/pages/life-style.js" /* webpackChunkName: "component---src-pages-life-style-js" */),
  "component---src-pages-lifestyle-en-js": () => import("./../../../src/pages/lifestyle.en.js" /* webpackChunkName: "component---src-pages-lifestyle-en-js" */),
  "component---src-pages-lifestyle-es-js": () => import("./../../../src/pages/lifestyle.es.js" /* webpackChunkName: "component---src-pages-lifestyle-es-js" */),
  "component---src-pages-lifestyle-js": () => import("./../../../src/pages/lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-los-agaves-js": () => import("./../../../src/pages/los-agaves.js" /* webpackChunkName: "component---src-pages-los-agaves-js" */),
  "component---src-pages-oxean-js": () => import("./../../../src/pages/oxean.js" /* webpackChunkName: "component---src-pages-oxean-js" */),
  "component---src-pages-puntamar-en-js": () => import("./../../../src/pages/puntamar.en.js" /* webpackChunkName: "component---src-pages-puntamar-en-js" */),
  "component---src-pages-puntamar-es-js": () => import("./../../../src/pages/puntamar.es.js" /* webpackChunkName: "component---src-pages-puntamar-es-js" */),
  "component---src-pages-puntamar-js": () => import("./../../../src/pages/puntamar.js" /* webpackChunkName: "component---src-pages-puntamar-js" */),
  "component---src-pages-tog-js": () => import("./../../../src/pages/tog.js" /* webpackChunkName: "component---src-pages-tog-js" */),
  "component---src-pages-zul-js": () => import("./../../../src/pages/zul.js" /* webpackChunkName: "component---src-pages-zul-js" */)
}

